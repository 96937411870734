import Panel from "../Panel";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
`;

const Loading = () => {
  return (
    <Wrapper>
      <Panel>
        <h1>Loading...</h1>
      </Panel>
    </Wrapper>
  );
};

export default Loading;
